import { ACCESS_TOKEN, ADMIN } from "../constant/constant";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

export const setStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
}

export const getStorage = (key: string) => {
  return localStorage.getItem(key);
}

export const removeStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const isAuth = () => {
  if (!getStorage(ACCESS_TOKEN)) return false;
  return true;
}

export const zodPatternValidation = (z: any, pattern: RegExp, message: string) => {
  return z.refine((value: any) => {
    if (value) return pattern.test(value);
    else return true;
  }, message);
}

const convert: any = (num: number) => {
  if (num === 0) {
    return '';
  } else if (num < 10) {
    return units[num];
  } else if (num === 10) {
    return tens[1]
  } else if (num < 20) {
    return teens[num - 11];
  } else if (num < 100) {
    return tens[Math.floor(num / 10)] + ' ' + units[num % 10];
  } else {
    return units[Math.floor(num / 100)] + ' Hundred ' + convert(num % 100);
  }
};

const convertToIndianWords: any = (num: number) => {
  if (num === 0) {
    return 'Zero';
  } else if (num >= 10000000) {
    const remainder = num % 10000000;
    const crorePart = Math.floor(num / 10000000);
    const crore = convert(crorePart) + (crorePart > 1 ? ' Crores ' : ' Crore ');
    const rest = convertToIndianWords(remainder);
    return rest !== 'Zero' ? crore + rest : crore;
  } else if (num >= 100000) {
    const remainder = num % 100000;
    const lakhPart = Math.floor(num / 100000);
    const lakh = convert(lakhPart) + (lakhPart > 1 ? ' Lakhs ' : ' Lakh ');
    const rest = convertToIndianWords(remainder);
    return rest !== 'Zero' ? lakh + rest : lakh;
  } else if (num >= 1000) {
    const remainder = num % 1000;
    const thousandPart = Math.floor(num / 1000);
    const thousand = convert(thousandPart) + ' Thousand ';
    const rest = convert(remainder);
    return rest !== 'Zero' ? thousand + rest : thousand;
  } else {
    return convert(num);
  }
};

export default convertToIndianWords;

export const DownloadCSV = (rows: any, fileName: any) => {
  const csv = Papa.unparse(rows);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};

export const formatNumber = (numberToBeFormatted: number, formatType = 'en-IN') => {
  return new Intl.NumberFormat(formatType).format(numberToBeFormatted);
}

export const getCreatePermission = (role: string, entity_name: string, props: any) => {
  const createPermission = `${role}_${entity_name}_create`;
  return props?.[createPermission] !== "true" && role !== ADMIN;
}

export const getUpdatePermission = (role: string, entity_name: string, props: any) => {
  const updatePermission = `${role}_${entity_name}_update`;
  return props?.[updatePermission] !== "true" && role !== ADMIN;
}

export const getDeletePermission = (role: string, entity_name: string, props: any) => {
  const deletePermission = `${role}_${entity_name}_delete`;
  return props?.[deletePermission] !== "true" && role !== ADMIN;
}

export const getReadPermission = (role: string, entity_name: string, props: any) => {
  const readPermission = `${role}_${entity_name}_read`;
  return props?.[readPermission] !== "true" && role !== ADMIN;
}
