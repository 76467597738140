import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import AppRunnerReducer from "./redux-slice"


const store = configureStore({
    reducer: {
        app_runner : AppRunnerReducer
    }
});

export const StoreProviderComponent = (props: any ) => {
    return (
        <Provider store={store}>
           {props.children}
        </Provider>
    )
}