import React from "react";
import notFoundImg from "../../asset/pagenotfound.png";
import Box from "@mui/material/Box";
import { Typography, Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./pagenotfound.module.scss";

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Stack className={styles.main}>
        <Typography variant="h1" className={styles.heading}>
          Page Not Found :(
        </Typography>
        <Typography variant="h3" className={styles.sub_heading}>
          Oops! 😖 The requested URL was not found on this server.
        </Typography>
        <Button
          variant="contained"
          className={styles.btn}
          onClick={handleLogout}
        >
          Back to home
        </Button>
        <img src={notFoundImg} alt="" className={styles.img} />
      </Stack>
    </Box>
  );
};

export default PageNotFound;
