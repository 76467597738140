import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  app_runner_list: [],
  users_me: {},
  isLoading: false,
  showError: [],
};

const AppRunnerSlice: any = createSlice({
  name: "app_runner",
  initialState,
  reducers: {
    UsersMe: (state, action) => {
      state.users_me = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    showErrorModal: (state, action) => {
      state.showError = action.payload;
    },
  },
});

export const { UsersMe, setLoading, showErrorModal } = AppRunnerSlice.actions;
export default AppRunnerSlice.reducer;
