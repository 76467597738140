import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import {
    PaletteColor,
    SimplePaletteColorOptions,
} from "@mui/material/styles";

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        mdx: true;
        lg: true;
        xl: true;
    }
    interface Palette {
        lightGrey: PaletteColor;
        placeHolder: PaletteColor;
    }

    interface PaletteOptions {
        lightGrey: SimplePaletteColorOptions;
        placeHolder: SimplePaletteColorOptions;
    }
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            mdx: 1024,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: '#696cff',
        },
        secondary: {
            main: 'rgba(105, 108, 255, 0.16)',
        },
        background: {
            default: "rgba(105, 108, 255, 0.16)",
        },
        mode: 'light',
        lightGrey: { main: "rgba(0, 0, 0, 0.04)" },
        placeHolder: { main: "#a1acb8" },
    },
    typography: {
        h1: {
            fontSize: '1.75rem',
            fontWeight: 700
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 600
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 500
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.53
        },
        caption: {
            fontSize: '0.85rem',
            fontWeight: 500
        },
        body1: {
            color: '#566a7f',
            fontSize: '0.9rem',
            fontWeight: 500,
        }
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: { color: "red" },
            },
        },
    },
});

export const AppThemeComponent = (props: { children: React.ReactNode }) => {
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}