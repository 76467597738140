import ReactDOM from 'react-dom/client';
import './styles/platform/index.scss';
import App from './App';
import { StoreProviderComponent } from './store/store-provider';
import { AppThemeComponent } from './components/theme/app-theme-provider.component';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <AppThemeComponent>
      <StoreProviderComponent>
        <App />
      </StoreProviderComponent>
    </AppThemeComponent>
);
