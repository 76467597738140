import { Box, Stack } from '@mui/system';
import React from 'react';
import { MoonLoader } from "react-spinners";

const LazyLoading: React.FC = () => {
  return (
    <Box>
      <Stack
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
          width: {xs: "100%", mdx:"calc(100vw - 16.25rem)"},
          height: "100%",
          background: "rgba(255, 255, 255, 0.4)",
          backdropFilter: "blur(3px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
        }}
      >
        <MoonLoader color="#696cff" />
      </Stack>
    </Box>
  )
}

export default LazyLoading